import * as React from 'react'
import cn from 'classnames'
import { Container, Row, Col } from 'react-awesome-styled-grid'

import { Block, Text, Title } from 'components/shared'
import { Spinner } from 'components/helpers'
import { Post } from 'components/app'

import * as S from './styled'

interface ViewProps {
  loadRef: any
  posts: BlogPost[]
  hasMore: boolean
}

const PostIndexView: React.FC<ViewProps> = ({ loadRef, posts, hasMore }) => (
  <>
    <Block tag="section" paddingTop="size88" paddingBottom="size88">
      <Container>
        <Block
          tag="header"
          display="flex"
          alignItems="center"
          marginBottom="size48"
        >
          <Title
            size="h3"
            icon={{
              name: 'news',
              color: 'brown800',
              width: 'size20',
              height: 'size20',
              marginLeft: 'size48',
              marginRight: 'size8'
            }}
            appearance="section"
            marginRight="size12"
            uppercase
          >
            Novidades
          </Title>
          <Text fontSize={14}>Confira as novidades das Lasca de Pizza</Text>
        </Block>
        <Block tag="article">
          <Row>
            {posts.map((post) => (
              <Col key={post.id} xs={12} sm={6} md={4}>
                <Block marginBottom="size32">
                  <Post
                    category={post.category.slug}
                    slug={post.slug}
                    title={post.title}
                    subtitle={post.subtitle}
                    image={post.image}
                  />
                </Block>
              </Col>
            ))}
          </Row>

          <Spinner
            className={cn({ invisible: !hasMore })}
            ref={loadRef}
            size="size32"
            color="brown800"
            marginLeft="auto"
            marginRight="auto"
          />
        </Block>
      </Container>
    </Block>
  </>
)

export default PostIndexView
