import React, { useState, useEffect, useRef } from 'react'
import { graphql, PageProps } from 'gatsby'

import { Seo } from 'components/helpers'
import { DefaultLayout } from 'components/layouts'

import PostIndex from 'views/PostIndex'

interface IndexPageProps extends PageProps {
  data: {
    allApiPosts: {
      nodes: []
    }
  }
}

const BlogPage: React.FC<IndexPageProps> = ({ data: { allApiPosts } }) => {
  const { nodes: allPosts } = allApiPosts

  const perLoad = 6
  const loadRef = useRef()

  const [posts, setPosts] = useState([...allPosts.slice(0, perLoad)])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(posts.length > perLoad)

  const handleObserver = (entities: any) => {
    const target = entities[0]

    if (target.isIntersecting) {
      setLoadMore(true)
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '-100px',
      threshold: 1.0
    })

    if (loadRef.current) {
      observer.observe(loadRef.current)
    }
  }, [])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = posts.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + perLoad)
        : []

      setPosts([...posts, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    setHasMore(posts.length < allPosts.length)
  }, [posts])

  return (
    <DefaultLayout>
      <Seo title="Blog" />
      <PostIndex loadRef={loadRef} posts={posts} hasMore={hasMore} />
    </DefaultLayout>
  )
}

export const query = graphql`
  query BlogQuery {
    allApiPosts(sort: { order: DESC, fields: posted_at }) {
      nodes {
        id
        slug
        category {
          slug
        }
        title
        subtitle
        image
      }
    }
  }
`

export default BlogPage
